import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Container,
    Paper,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import GlobalContext from "../../../hooks/GlobalContext";
import createVisit from "../../../firebase/httpsCallable/createVisit";
import getPatientTracking from "../../../firebase/httpsCallable/getPatientTracking";
import { useTimer } from "./TimerContext";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";

export default function(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const { mainCtx } = useContext(GlobalContext);
    const [sending, setSending] = useState(false);
    const { onTick } = useTimer();
    const modalSecondsRemainingRef = useRef(0);
    const [modalSecondsRemaining, setModalSecondsRemaining] = useState(0);
    const [requestState, setRequestState] = useState("success");
    const [appointmentTime, setAppointmentTime] = useState(Date.now());
    const [arrivalTime, setArrivalTime] = useState(Date.now());
    const [option, setOption] = useState({})

    useEffect(() => {
        onTick(decrementModalSeconds);
        getInfoTracking(mainCtx.officeId);
        return () => {
            onTick(() => {});
        };
    }, []);

    const getInfoTracking = (officeId) => {
        getPatientTracking({
            officeId: officeId,
        })
        .then((resul) => {
            const data = resul.data && resul.data.visitLate;
            setOption(data)
        })
        .catch(err => {
            setOption({})
        });
    }

    function decrementModalSeconds() {
        modalSecondsRemainingRef.current -= 1;
        setModalSecondsRemaining(modalSecondsRemainingRef.current);
    }

    function startModal(sec) {
        modalSecondsRemainingRef.current = sec;
        setModalSecondsRemaining(sec);
    }

    function clearFields() {
        setFirstName("");
        setLastName("");
        setAppointmentTime(Date.now());
    }

    async function handleSubmit() {
        setSending(true);
        await createVisit({
            officeId: mainCtx.officeId,
            patientName: firstName,
            lastName: lastName,
            appointmentTime: option && option.lateArrivals === 'yes' ? arrivalTime > appointmentTime ? arrivalTime : appointmentTime : appointmentTime,
            arrivalTime: arrivalTime,
            dueOutTime: Date.now(),
            lateColor:  option && option.lateArrivals === 'yes' ? arrivalTime > appointmentTime ? '#df3c3c' : '' : '',
        }).catch(err => {
            setRequestState("error");
        });
        setRequestState("success");
        setSending(false);
        startModal(2);
        clearFields();
    }

    return (
        <Grid
            container
            style={{ minHeight: "80vh" }}
            justify="center"
            alignItems="center"
        >
            <Paper style={{ height: "100%", padding: "20px" }}>
                {!sending && (
                    <Grid
                        container
                        spacing={2}
                        justify="center"
                        style={{ textAlign: "center" }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                label="First Name"
                                type="text"
                                value={firstName}
                                onKeyPress={e => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                                onChange={e => {
                                    setFirstName(e.target.value);
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Last Name"
                                type="text"
                                value={lastName}
                                onKeyPress={e => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                                onChange={e => {
                                    setLastName(e.target.value);
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="appointment time"
                                    label="Appointment Time"
                                    value={appointmentTime}
                                    onKeyPress={e => {
                                        if (e.key === "Enter") {
                                            handleSubmit();
                                        }
                                    }}
                                    onChange={e => {
                                        const time = e
                                            ? e.valueOf()
                                            : Date.now();
                                        setAppointmentTime(time);
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change time"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleSubmit}>
                                Sign In
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {sending && <CircularProgress></CircularProgress>}
            </Paper>
            <Dialog open={modalSecondsRemaining > 0}>
                <DialogContent>
                    {requestState === "success" && (
                        <DoneIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></DoneIcon>
                    )}
                    {requestState === "error" && (
                        <ErrorIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></ErrorIcon>
                    )}
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
