import firebase from "firebase/app";
import "firebase/firebase-functions";
import "firebase/firebase-auth";
import "firebase/firebase-firestore";
import "firebase/storage";

var configs = {
    dev: {
        apiKey: "AIzaSyB1LmaL9TvkLNtb-lD4_5NqpT91sv_YKAM",
        authDomain: "fir-test-62d25.firebaseapp.com",
        databaseURL: "https://fir-test-62d25.firebaseio.com",
        projectId: "fir-test-62d25",
        messagingSenderId: "761730117141",
        storageBucket: "gs://fir-test-62d25.appspot.com"
    },
    kaizen_dev: {
        apiKey: "AIzaSyDinyaej54uUquUMyiCfN3KRtpTGJw5Fow",
        authDomain: "insync-kaizen-dev.firebaseapp.com",
        databaseURL: "https://insync-kaizen-dev-default-rtdb.firebaseio.com",
        projectId: "insync-kaizen-dev",
        storageBucket: "insync-kaizen-dev.appspot.com",
        messagingSenderId: "563116740419",
        appId: "1:563116740419:web:eb471c3592b220704029f6",
    },
    kaizen_staging: {
        apiKey: "AIzaSyA52dmAqltkaugnVQKjHddZecEpAAheLZI",
        authDomain: "insync-kaizen-stg-8d455.firebaseapp.com",
        databaseURL: "https://insync-kaizen-stg-8d455-default-rtdb.firebaseio.com",
        projectId: "insync-kaizen-stg-8d455",
        storageBucket: "insync-kaizen-stg-8d455.appspot.com",
        messagingSenderId: "952387116790",
        appId: "1:952387116790:web:c0366f3f1a5ce1cc8716d7",
    },
    kaizen_prod: {
        apiKey: "AIzaSyB8IlCsMcdQ-zFjlrVadc9IecY_3Q7KJS8",
        authDomain: "insync-kaizen-stg.firebaseapp.com",
        databaseURL: "https://insync-kaizen-stg-default-rtdb.firebaseio.com",
        projectId: "insync-kaizen-stg",
        storageBucket: "insync-kaizen-stg.appspot.com",
        messagingSenderId: "797745920570",
        appId: "1:797745920570:web:09e398ee0f5eb882bed303"
      },
    prod: {
        apiKey: "AIzaSyCuxwzN2nOKWsTxTxg2iAt4YwjyUx64ri4",
        authDomain: "insync-fe766.firebaseapp.com",
        databaseURL: "https://insync-fe766.firebaseio.com",
        projectId: "insync-fe766",
        messagingSenderId: "770733575657",
        appId: "1:770733575657:web:8e0395fd2ef634e4",
        storageBucket: "gs://fir-test-62d25.appspot.com"
    }
};

// eslint-disable-next-line
const mode = process.env.REACT_APP_MODE || 'kaizen_prod';
const app = firebase.initializeApp(configs[mode]);

// app.functions().useFunctionsEmulator("http://localhost:5000");

export default firebase;

window.firebase = firebase;
